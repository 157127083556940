import React, {createElement, Fragment, useState} from "react";
import qs from "qs";
import colors from "../../lib/colors";
import styled from "@emotion/styled";
import Ui from "../../components/Ui";
import fetch from "../../lib/fetch";
import {PlainButton} from "../../components/Button";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import {Text} from "../../components/ui/Text";
import Col from "../../components/ui/Col";

const UnsubscribeLegacySection = ({userName, email, token}) => {
  const [state, setState] = useState({done: false, error: null});

  const handleUnsub = () => {
    fetch(`/services/apply-unsub-token`, {method: "post", body: JSON.stringify({token})}).then(
      () => {
        setState({done: true, error: null});
      },
      e => setState({done: false, error: (e || "some thing went wrong :(").toString()})
    );
  };
  return (
    <Col sp={4}>
      <Col sp={1}>
        <Text size={5}>Hi {userName},</Text>
        <Text size={3}>
          Click the button below to no longer receive updates for <b>{email}</b>.
        </Text>
      </Col>
      {state.done ? (
        <Text size={3} preset="bold" color="purple200">
          Preferences updated.
        </Text>
      ) : (
        <PlainButton size="small" onClick={handleUnsub}>
          Unsubscribe
        </PlainButton>
      )}
      {state && state.error && (
        <Text color="error100" preset="bold" align="center">
          {state.error}
        </Text>
      )}
    </Col>
  );
};

const joinByBr = list => {
  const lines = [];
  list.forEach(entry => lines.push(entry, <br />));
  return createElement(Fragment, {}, ...lines.slice(0, -1));
};

const UnsubscribeNamedSection = ({token, message}) => {
  const [state, setState] = useState({done: false, error: null, message: null});

  const handleUnsub = () => {
    fetch(`/services/apply-named-unsub-token`, {
      method: "post",
      body: JSON.stringify({token}),
    }).then(
      data => {
        setState({done: true, error: null, message: data.message});
      },
      e => setState({done: false, error: (e || "some thing went wrong :(").toString()})
    );
  };
  return (
    <Col sp={4}>
      <Col sp={1}>
        <Text size={3}>{joinByBr(message.split("\n"))}</Text>
      </Col>
      {state.done ? (
        <Text size={3} preset="bold" color="purple200">
          {state.message || "Preferences updated."}
        </Text>
      ) : (
        <PlainButton size="small" onClick={handleUnsub}>
          Unsubscribe
        </PlainButton>
      )}
      {state && state.error && (
        <Text color="error100" preset="bold" align="center">
          {state.error}
        </Text>
      )}
    </Col>
  );
};

const useTokenInfo = params => {
  const [state, set] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const type = params.token ? "legacy" : "named";
  const url = `/services/${type === "named" ? "check-named-unsub-token" : "check-unsub-token"}`;
  const token = params.t || params.token;
  React.useEffect(() => {
    if (!token) return null;
    setLoading(true);
    fetch(url, {method: "POST", body: JSON.stringify({token})})
      .then(
        response => {
          set(response);
        },
        e => {
          if (e === "Invalid Token") {
            set({error: "Invalid Token. Please double check the link."});
          } else {
            set({error: "There's been an error with validating the token"});
            console.error(e);
          }
        }
      )
      .finally(() => setLoading(false));
  }, [token, url]);
  return {state, type, loading, token};
};

const Body = styled(Ui.Col)({
  flex: "auto",
  padding: "3rem 2rem 5rem",
  backgroundColor: colors.brandDim,
  borderTop: `0.05rem solid ${colors.fade(colors.white, 0.1)}`,
  "@media(max-width: 350px)": {paddingLeft: "1rem", paddingRight: "1rem"},
});

const BodyContent = styled("div")({
  width: "100%",
  maxWidth: "25rem",
  margin: "0 auto",
});

const UnsubscribePage = ({location}) => {
  const params = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : {};
  const {state, type, token} = useTokenInfo(params);

  return (
    <DefaultLayout title="Subscription Preferences" location={location} footer={null}>
      <Hero size="sm" title="Unsubscribe from Codecks updates" />
      <Body>
        <BodyContent>
          {state && state.error && (
            <Text color="error100" preset="bold" align="center">
              {state.error}
            </Text>
          )}
          {state && type === "legacy" && state.userName && (
            <UnsubscribeLegacySection userName={state.userName} email={state.email} token={token} />
          )}
          {state && type === "named" && state.message && (
            <UnsubscribeNamedSection token={token} message={state.message} />
          )}
        </BodyContent>
      </Body>
    </DefaultLayout>
  );
};

export default UnsubscribePage;
